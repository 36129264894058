import React from 'react';

const Pin = ({ borderColor = '#990000', backgroundColor = '#cccccc', glyphColor = '#990000' }) => {
    return (
        <>
            <div
                style={{
                    backgroundColor: backgroundColor,
                    width: '30px',
                    height: '30px',
                    borderRadius: '50%',
                    borderColor: borderColor,
                    borderWidth: '1px',
                    borderStyle: 'solid',
                }}>
                <div
                    style={{
                        backgroundColor: glyphColor,
                        width: '10px',
                        height: '10px',
                        borderRadius: '50%',
                        margin: '9px',
                    }}
                ></div>
            </div>
        </>
    );
};

export default Pin;
