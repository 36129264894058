import {Component, React} from 'react';
import ReactDOM from 'react-dom';
import {Modal, Tab, Tabs} from 'react-bootstrap';
import FormAdicionarUnidade from './FormAdicionarUnidade';
import IntlCurrencyInput from 'react-intl-currency-input';
import FormDepesas from './FormDespesa';
import TabelaDespesas from './TabelaDespesas';
import DemonstrativoResultados from './DemonstrativoResultados';
import FluxoTotalTabela from './FluxoTotalTabela';
import {v4 as uuidv4} from 'uuid';
import Loader from '../Loader';
import TableFormVendas from './TableFormVendas';
import FormAdicionarVenda from './FormAdicionarVenda';
import SweetAlert2 from 'react-sweetalert2';
import {InputNumber} from 'primereact/inputnumber';
import ModalVersions from './ModalVersions';
import {Calendar} from 'primereact/calendar';
import FluxoTotalChart from "./FluxoTotalChart";
import {collect} from 'collect.js';

const currencyConfig = {
    locale: 'pt-BR',
    formats: {
        number: {
            BRL: {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
        },
    },
};

const ptBr = {
    firstDayOfWeek: 1,
    dayNames: ["domingo", "Segunda-Feira", "Terça-Feira", "Quarta-Feira", "Quinta-Feira", "Sexta-Feira", "Sábado"],
    dayNamesShort: ["dom", "seg", "ter", "qua", "qui", "sext", "sab"],
    dayNamesMin: ["DOM", "SEG", "TER", "QUA", "QUI", "SEX", "SAB"],
    monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
    monthNamesShort: ["jan", "fev", "mar", "abr", "mai", "jun", "jul", "ago", "set", "out", "nov", "dez"],
    today: "Hoje",
    clear: "Limpar"
};

class AnaliseForm extends Component {
    onValueChangeCheckBox = (e) => {
        this.setState({
            [e.target.name]: e.target.value === 'Sim'
        });
    }


    timeout = null;


    constructor(props) {
        super(props);

        let grupoUuid = route().params.grupo;

        this.state = {
            analise_id: 0,
            analise: {
                nome: "",
                uuid: uuidv4(),
                local: "",
                versions: [],
                data_lancamento: ''
            },
            area_equivalente_total: 0,
            area_privativa_media: 0,
            area_privativa_total: 0,
            areas_comuns: "",
            custo_total: 0,
            custo_unitario_basico: 0,
            despesas: [
                {
                    descriminacao: "Terreno",
                    valor: 0,
                    momento: 1,
                    numero_parcelas: 1
                }, {
                    descriminacao: "Custos de Estudos e Projetos",
                    valor: 0,
                    momento: 1,
                    numero_parcelas: 1
                }, {
                    descriminacao: "Custos de legalização e regularização",
                    valor: 0,
                    momento: 1,
                    numero_parcelas: 1
                }
            ],
            grupoUuid: grupoUuid,
            imovel_uuid: uuidv4(),
            permutas: 0,
            periodo_obras: 0,
            porcentagem_financiamento: 0,
            porcentagem_administracao_escritorio: 0,
            porcentagem_areas_comuns: 0,
            porcentagem_comissao_sobre_vendas: 0,
            porcentagem_custo_manutencao_futura: 0,
            porcentagem_custo_publicidade_pre_lancamento: 0,
            porcentagem_custo_publicidade_lancamento: 0,
            porcentagem_custo_publicidade_pos_lancamento: 0,
            porcentagem_impostos_sobre_faturamento: 0,
            porcentagem_permuta_financeira: 0,
            porcentagem_taxa_juros_financiamento: 0,
            pos_obra_numero_parcelas: 0,
            pos_obra_porcentagem_contrato: 0,
            pos_obra_porcentagem_entrada: 0,
            pos_obra_porcentagem_parcelas: 0,
            possui_orcamento: false,
            possui_permuta_financeira: false,
            pre_obra_numero_parcelas: 0,
            pre_obra_porcentagem_contrato: 0,
            pre_obra_porcentagem_entrada: 0,
            pre_obra_porcentagem_parcelas: 0,
            resultados: {
                investimento_maximo: 0,
                total_juros: 0,
                tir: 0,
                vpl: 0,
                rpl: 0,
                ipl: 0,
                ipl_proprio: 0,
                margem_liquida: 0,
                resultado_1: 0,
                resultado_2: 0,
                receita_liquida: 0,
                analise_uuid: '',
            },
            unidades: [],
            valor_comissao_sobre_terreno: 0,
            valor_medio_vendas: 0,
            valor_venal_total: 0,
            vendas_durante_obra: [],
            vendas_pos_obra: [],
            vendas_pre_obra: [],
            total_unidades: 0,
            loading: false,
            saved: false,
            vendas_por_unidade: {},
            errors: {},
            swal: {
                title: 'Remover Unidade',
                text: 'Ao remover a unidade todas as vendas desta unidade serão removidas. Deseja continuar?',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                confirmButtonColor: 'red',
                cancelButtonColor: 'green',
                show: false
            },
            selectedUnidade: null,
            editingUnidade: false,
            selectedDespesa: null,
            editingDespesa: false,
            loading_resultados_dasboard: false,
            loading_fluxo_total: false,
            fluxo_total: [],
            evolucao_obra: [],
            showModalVersions: false,
            versions: [],
            show_modal_preencher_vendas: false,
            erro_calculo: null,
            downloading: false,
            porcentagem_vendas_pre_obra: null,
            porcentagem_vendas_durante_obra: null,
            porcentagem_vendas_pos_obra: null,
        };

        this.timeout = null

    }

    componentDidMount = async () => {
        let routeParams = route().params;

        console.log(routeParams);

        if (routeParams.analise) {
            await this.loadData(routeParams.analise);
        }
    }

    loadData = async (analiseUuid) => {
        await this.loadAnalise(analiseUuid);
        this.getResultsDashboard();
        this.getFluxoTotalData();
    }

    getResultsDashboard = async () => {
        try {

            this.setState({
                ...this.state,
                loading_resultados_dasboard: true
            });

            const response = await axios.get(route('viabilidade.dasboard.show', this.state.analise.uuid));

            this.setState({
                ...this.state,
                resultados: response.data
            });

        } catch (e) {
            console.log(e);
        } finally {
            this.setState({
                ...this.state,
                loading_resultados_dasboard: false
            });
        }


    }

    updateVendaQuantidadeVenda = (venda, quantidade, periodo_vendas) => {

        if (this.timeout) {
            clearTimeout(this.timeout);
        }


        this.timeout = setTimeout(async () => {

            if (isNaN(quantidade)) {
                quantidade = 0;
            }

            if (quantidade > venda.oferta_inicial) {
                alert("O número de vendas não pode ser maior que a quantidade de unidades.");
                return;
            }

            if (quantidade > (venda.estoque + venda.numero_vendas)) {
                alert("O número de vendas não pode ser maior que o estoque da unidade.");
                return;
            }


            let vendasPreObra = this.state.vendas_pre_obra;
            let vendasPosObra = this.state.vendas_pos_obra;
            let vendasDuranteObra = this.state.vendas_durante_obra;


            let vendidas = 0;

            let updateVenda = (v) => {

                if (v.uuid === venda.uuid) {


                    let numeroVendas = parseInt(v.numero_vendas);

                    if (v.venda_uuid === venda.venda_uuid) {
                        numeroVendas = parseInt(quantidade);
                    }

                    vendidas += parseInt(numeroVendas);

                    return {
                        ...v,
                        numero_vendas: numeroVendas,
                        vendidas: vendidas,
                        estoque: v.oferta_inicial - vendidas
                    }
                }

                return v;
            };

            vendasPreObra = vendasPreObra.map(updateVenda);
            vendasDuranteObra = vendasDuranteObra.map(updateVenda);
            vendasPosObra = vendasPosObra.map(updateVenda);


            this.setState({
                ...this.state,
                vendas_pre_obra: vendasPreObra,
                vendas_pos_obra: vendasPosObra,
                vendas_durante_obra: vendasDuranteObra
            });
        }, 200);

    }

    setLoading = (value) => {
        this.setState({
            loading: value
        });

    }

    loadAnalise = async (analiseUuid) => {
        try {
            this.setLoading(true);

            let response = await axios.get(route('viabilidade.analise.show', {uuid: analiseUuid}));

            let data = response.data;

            this.setState({
                ...data.data,
                analise_id: data.data.analise.id,
                saved: true
            })

            this.loadVersions();

        } catch (error) {
            console.log(error);

        } finally {
            this.setLoading(false);
        }
    }


    formatNumber = (number) => {
        return Number(number).toLocaleString('pt-BR', {maximumFractionDigits: 2, minimumFractionDigits: 2});
    }

    calculateVgv = () => {
        let vgv = 0;

        this.state.unidades.forEach((unidade) => {
            vgv += unidade.preco * unidade.quantidade;
        });

        return vgv;

    }

    onValueChange = async (e) => {
        let fieldName = e.target.name;
        let value = e.target.value;

        await this.setState({
            ...this.state,
            [fieldName]: value
        });
    }

    onChangePorcentagemAreasComuns = async (e) => {

        let value = parseFloat(e.target.value);

        console.log(e, value);

        await this.setState({
            ...this.state,
            porcentagem_areas_comuns: value
        })

        await this.recalcularMetaInformacoesDeUnidades();

    };

    addDespesa = (despesa) => {

        let newDespesas = this.state.despesas.map(d => d.uuid === despesa.uuid ? despesa : d);

        if (!this.state.editingDespesa) {
            newDespesas = [...newDespesas, despesa];
        }

        this.setState({
            ...this.state,
            despesas: newDespesas,
            editingDespesa: false,
            selectedDespesa: null
        });
    }

    removeDespesa = (index) => {
        let despesas = this.state.despesas;
        this.setState({
            ...this.state,
            despesas: despesas.filter((u, i) => i !== index)
        });
    }

    adicionarUnidade = async (unidade) => {

        unidade.preco = unidade.preco_metro * unidade.area_privativa;

        let newUnidades = this.state.unidades.map(u => u.uuid === unidade.uuid ? unidade : u);

        if (!this.state.editingUnidade) {
            newUnidades = [...newUnidades, unidade];
        }

        await this.setState({
            ...this.state,
            unidades: newUnidades,
            editingUnidade: false,
            selectedUnidade: null
        });

        await this.recalcularMetaInformacoesDeUnidades();
    }

    recalcularMetaInformacoesDeUnidades = async () => {

        let newUnidades = this.state.unidades;

        let areaPrivativaTotal = this.getAreaPrivativaTotal(newUnidades);
        let areasComuns = this.getAreaComun(this.state.porcentagem_areas_comuns, areaPrivativaTotal);

        let precoMedioMetro = this.calculatePrecoMedioMetroUnidade();

        await this.setState({
            area_privativa_total: areaPrivativaTotal,
            total_unidades: this.getTotalUnidades(newUnidades),
            area_privativa_media: this.getAreaMediaUnidade(),
            areas_comuns: areasComuns,
            area_equivalente_total: areaPrivativaTotal + areasComuns,
            custo_total: this.getCustoTotal(),
            valor_medio_vendas: precoMedioMetro
        });
    }

    removerUnidade = async (uuid) => {
        let newUnidades = this.state.unidades.filter((u, i) => uuid !== u.uuid);

        let vendasPreObra = this.state.vendas_pre_obra.filter(v => v.uuid !== uuid);
        let vendasDuranteObra = this.state.vendas_durante_obra.filter(v => v.uuid !== uuid);
        let vendasPosObra = this.state.vendas_pos_obra.filter(v => v.uuid !== uuid);

        await this.setState({
            ...this.state,
            unidades: newUnidades,
            vendas_pre_obra: vendasPreObra,
            vendas_durante_obra: vendasDuranteObra,
            vendas_pos_obra: vendasPosObra,
            selectedUnidade: null
        });

        await this.recalcularMetaInformacoesDeUnidades();
    }


    getAreaComun = (porcentagem, areaTotal) => {
        return (porcentagem * areaTotal) / 100;
    }

    getAreaMediaUnidade = () => {
        let areaTotalPrivativa = this.state.unidades.reduce((current, unidade) => current + parseInt(unidade.area_privativa) * parseInt(unidade.quantidade), 0);
        let totalUnidades = this.state.unidades.reduce((current, unidade) => current + parseInt(unidade.quantidade), 0);
        let areaMedia = areaTotalPrivativa / totalUnidades;

        if (isNaN(areaMedia)) {
            return 0;
        }

        return areaMedia;
    }

    /**
     * Valor Médio Metro = soma do valor do metro de todas as unidades / total de unidades
     */
    calculatePrecoMedioMetroUnidade = () => {
        let totalUnidades = this.state.unidades.reduce((current, unidade) => current + parseInt(unidade.quantidade), 0);
        let totalValorMetro = this.state.unidades.reduce(
            (current, unidade) => {
                let precoMetro = parseFloat(unidade.preco) / parseFloat(unidade.area_privativa);

                return current + precoMetro * parseInt(unidade.quantidade);
            }, 0);

        return totalValorMetro / totalUnidades;
    }

    addVenda = (venda) => {
        //só pode adicionar um novo mês que não exista nas tabelas de vendas

        let maxMes = Math.max(...this.state.vendas_pos_obra.map(v => v.mes));

        if (venda.mes < maxMes) {
            alert('Não é possível adicionar um mês menor que o último mês adicionado.');
            return;
        }

        let vendaExists = this.state.vendas_pos_obra.find(v => v.mes === venda.mes && v.uuid === venda.uuid);

        if (vendaExists) {
            alert('Já existe uma venda para esse mês.');
            return;
        }

        let ultimaVendaUnidade = this.state.vendas_pos_obra.find(v => v.uuid === venda.uuid);

        if (ultimaVendaUnidade) {
            venda.estoque = ultimaVendaUnidade.estoque - venda.numero_vendas;
            venda.vendidas = ultimaVendaUnidade.vendidas + venda.numero_vendas;
        }

        this.setState({
            ...this.state,
            vendas_pos_obra: [...this.state.vendas_pos_obra, venda]
        });
    }

    checkEstoqueNegativo = async () => {
        let vendasPreObra = this.state.vendas_pre_obra;
        let vendasDuranteObra = this.state.vendas_durante_obra;
        let vendasPosObra = this.state.vendas_pos_obra;

        let vendas = [...vendasPreObra, ...vendasDuranteObra, ...vendasPosObra];

        let vendasNegativas = vendas.filter(v => v.estoque < 0);

        if (vendasNegativas.length > 0) {
            let message = "Existem vendas com estoque negativo. Por favor, corrija antes de salvar a análise.";

            this.setState({
                errors: {
                    estoque_negativo: [message]
                }
            });

            //scroll top
            window.scrollTo(0, 0);

            throw new Error(message);
        }
    }

    setSelectDespesa = (despesa) => {
        this.setState({
            selectedDespesa: despesa,
            editingDespesa: true
        });

    }

    getFluxoTotalData = async () => {
        try {

            this.setState({
                ...this.state,
                loading_fluxo_total: true
            });

            let [data, evolucao] = await Promise.all([
                this.getFluxoData(),
                this.loadEvolucaoObra()
            ]);

            this.setState({
                ...this.state,
                fluxo_total: data,
                evolucao_obra: evolucao,
            });

        } catch (e) {
            console.log(e);
        } finally {
            this.setState({
                ...this.state,
                loading_fluxo_total: false
            })
        }
    }

    getFluxoData = async () => {
        let response = await axios.get(route('viabilidade.fluxo_total.show', {
            'uuid': this.state.analise.uuid
        }));

        return response.data;
    }

    loadEvolucaoObra = async () => {
        try {
            let response = await axios.get(route('viabilidade.evolucao_obra.show', {
                uuid: this.state.imovel_uuid
            }));

            return response.data;
        } catch (e) {
            console.log(e);
        }
    }

    submitNewVersion = async () => {

        let parent = this.state.analise_id;

        if (parseInt(this.state.analise.parent_id) > 0) {
            parent = this.state.analise.parent_id;
        }

        let vendasPreObra = this.state.vendas_pre_obra;
        let vendasDuranteObra = this.state.vendas_durante_obra;
        let vendasPosObra = this.state.vendas_pos_obra;


        let updateCamposVenda = (venda, unidade, newUuid) => {
            if (venda.uuid === unidade.uuid) {
                return {
                    ...venda,
                    uuid: newUuid,
                    venda_uuid: uuidv4(),
                    unidade_uuid: newUuid,
                    parent_id: parent
                }
            }

            return venda;
        }

        let unidades = this.state.unidades.map((unidade) => {

            let newUuid = uuidv4();

            vendasPreObra = vendasPreObra.map(v => updateCamposVenda(v, unidade, newUuid));
            vendasDuranteObra = vendasDuranteObra.map(v => updateCamposVenda(v, unidade, newUuid));
            vendasPosObra = vendasPosObra.map(v => updateCamposVenda(v, unidade, newUuid));

            let newUnidade = {
                ...unidade,
                uuid: newUuid,
                parent_id: parent
            }

            return newUnidade;
        })

        let despesas = this.state.despesas.map((despesa) => {
            return {
                ...despesa,
                uuid: uuidv4(),
                parent_id: parent
            }
        })

        this.setState({
            ...this.state,
            analise: {
                ...this.state.analise,
                uuid: uuidv4(),
                parent_id: parent
            },
            imovel_uuid: uuidv4(),
            vendas_pre_obra: vendasPreObra,
            vendas_durante_obra: vendasDuranteObra,
            vendas_pos_obra: vendasPosObra,
            unidades: unidades,
            despesas: despesas,

        })

        return this.submit();
    }

    calcularVendas = async () => {

        this.setState({
            erro_calculo: null
        });

        const {porcentagem_vendas_pre_obra, porcentagem_vendas_durante_obra, porcentagem_vendas_pos_obra} = this.state;

        if (![porcentagem_vendas_pre_obra, porcentagem_vendas_durante_obra, porcentagem_vendas_pos_obra].every(Boolean)) {
            this.setState({
                erro_calculo: "Preencha todos os campos"
            });

            return;
        }

        try {
            await this.setLoading(true);

            const vendasPorUnidade = {};

            const vendasPorUnidadePorEtapa = {};

            this.state.unidades.map((unidade) => vendasPorUnidade[unidade.uuid] = 0);

            const etapas = ['pre', 'durante', 'pos'];

            etapas.map((etapa) => {
                this.state.unidades.map((unidade) => vendasPorUnidadePorEtapa[`${unidade.uuid}_${etapa}`] = 0);
            });

            const calculo = (venda, indiceVenda, indiceEtapa, etapa ) => {

                let unidade = this.state.unidades.find(u => u.uuid === venda.unidade_uuid);

                let maxVendasEtapaPorUnidade = Math.round(unidade.quantidade * (indiceEtapa / 100));

                if (vendasPorUnidade[unidade.uuid] === unidade.quantidade) {
                    venda.numero_vendas = 0;
                    venda.estoque = 0;
                    return venda;
                }


                let unidadesVendidas = Math.round(unidade.quantidade * (indiceVenda / 100));

                //não pode passar de maxVendasEtapaPorUnidade
                let etapaUnidadeKey = `${unidade.uuid}_${etapa}`;
                if( vendasPorUnidadePorEtapa[etapaUnidadeKey]  + unidadesVendidas > maxVendasEtapaPorUnidade ) {
                    venda.numero_vendas = 0;
                    venda.estoque = unidade.quantidade - vendasPorUnidade[unidade.uuid];
                    return venda;
                }

                if (unidadesVendidas > unidade.quantidade) {
                    unidadesVendidas = unidade.quantidade;
                }

                if (vendasPorUnidade[unidade.uuid] + unidadesVendidas > unidade.quantidade) {
                    unidadesVendidas = unidade.quantidade - vendasPorUnidade[unidade.uuid];
                }

                vendasPorUnidade[unidade.uuid] += unidadesVendidas;
                vendasPorUnidadePorEtapa[etapaUnidadeKey] += unidadesVendidas;

                venda.numero_vendas = unidadesVendidas;
                venda.estoque = unidade.quantidade - vendasPorUnidade[unidade.uuid];

                return venda;
            };

            let mesesPreObra = collect(this.state.vendas_pre_obra).unique('mes').count();
            let mesesDuranteObra = collect(this.state.vendas_durante_obra).unique('mes').count();
            let mesesPosObra = collect(this.state.vendas_pos_obra).unique('mes').count();

            let indiceVendasPreObra = this.state.porcentagem_vendas_pre_obra / mesesPreObra;

            let indiceVendasDuranteObra = this.state.porcentagem_vendas_durante_obra / mesesDuranteObra;
            let indiceVendasPosObra = this.state.porcentagem_vendas_pos_obra / mesesPosObra;

            let vendasPreObra = this.state.vendas_pre_obra.map(
                (venda) => calculo(venda, indiceVendasPreObra, this.state.porcentagem_vendas_pre_obra, 'pre' )
            );
            let vendasDuranteObra = this.state.vendas_durante_obra.map(
                (venda) => calculo(venda, indiceVendasDuranteObra, this.state.porcentagem_vendas_durante_obra, 'durante' )
            );

            let vendasPosObra = this.state.vendas_pos_obra.map(
                (venda) => calculo(venda, indiceVendasPosObra, this.state.porcentagem_vendas_pos_obra, 'pos' )
            );

            await this.setState({
                ...this.state,
                vendas_pre_obra: vendasPreObra,
                vendas_durante_obra: vendasDuranteObra,
                vendas_pos_obra: vendasPosObra,
                show_modal_preencher_vendas: false
            })


        } catch (error) {
            await this.setState({
                ...this.state,
                erro_calculo: error.message
            })
        } finally {
            await this.setLoading(false);
        }
    }

    submit = async () => {

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        await this.checkEstoqueNegativo();

        let vendasPreObra = this.state.vendas_pre_obra;
        if (this.state.analise_id > 0 && this.state.vendas_pre_obra.length === 0 && this.state.mes_inicio_obra > 1) {
            vendasPreObra = [{
                uuid: this.state.unidades[0].uuid,
                unidade_uuid: this.state.unidades[0].uuid,
                mes: 1,
                numero_vendas: 0,
                oferta_inicial: this.state.total_unidades,
                estoque: this.state.total_unidades,
                vendidas: 0
            }];
        }

        let data = {
            analise_id: this.state.analise_id,
            analise: this.state.analise,
            despesas: this.state.despesas,
            grupo_uuid: this.state.grupoUuid,
            custo_oportunidade: this.state.custo_oportunidade,
            imovel: {
                area_privativa_media: this.state.area_privativa_media,
                area_privativa_total: this.state.area_privativa_total,
                area_equivalente_total: this.state.area_equivalente_total,
                custo_total: this.getCustoTotal(),
                custo_unitario_basico: this.state.custo_unitario_basico,
                mes_inicio_obra: this.state.mes_inicio_obra || 1,
                periodo_de_obras: this.state.periodo_obras,
                porcentagem_financiamento: this.state.porcentagem_financiamento,
                porcentagem_administracao_escritorio: this.state.porcentagem_administracao_escritorio,
                porcentagem_areas_comuns: this.state.porcentagem_areas_comuns,
                porcentagem_comissao_sobre_vendas: this.state.porcentagem_comissao_sobre_vendas,
                porcentagem_custo_manutencao_futura: this.state.porcentagem_custo_manutencao_futura,
                porcentagem_custo_publicidade_pre_lancamento: this.state.porcentagem_custo_publicidade_pre_lancamento,
                porcentagem_custo_publicidade_lancamento: this.state.porcentagem_custo_publicidade_lancamento,
                porcentagem_custo_publicidade_pos_lancamento: this.state.porcentagem_custo_publicidade_pos_lancamento,
                porcentagem_impostos_sobre_faturamento: this.state.porcentagem_impostos_sobre_faturamento,
                porcentagem_permuta_financeira: this.state.porcentagem_permuta_financeira,
                porcentagem_taxa_juros_financiamento: this.state.porcentagem_taxa_juros_financiamento,
                pos_obra_numero_parcelas: this.state.pos_obra_numero_parcelas,
                pos_obra_porcentagem_contrato: this.state.pos_obra_porcentagem_contrato,
                pos_obra_porcentagem_entrada: this.state.pos_obra_porcentagem_entrada,
                pos_obra_porcentagem_parcelas: this.state.pos_obra_porcentagem_parcelas,
                possui_orcamento: this.state.possui_orcamento,
                possui_permuta_financeira: this.state.possui_permuta_financeira,
                pre_obra_numero_parcelas: this.state.pre_obra_numero_parcelas,
                pre_obra_porcentagem_contrato: this.state.pre_obra_porcentagem_contrato,
                pre_obra_porcentagem_entrada: this.state.pre_obra_porcentagem_entrada,
                pre_obra_porcentagem_parcelas: this.state.pre_obra_porcentagem_parcelas,
                unidades: this.state.unidades,
                uuid: this.state.imovel_uuid,
                valor_comissao_sobre_terreno: this.state.valor_comissao_sobre_terreno,
                valor_medio_vendas: this.state.valor_medio_vendas,
                valor_venal_total: this.calculateVgv(),
            },
            vendas: {

                durante_obra: {
                    vendas: this.state.vendas_durante_obra,
                },

                pos_obra: {
                    vendas: this.state.vendas_pos_obra,
                },

                pre_obra: {
                    vendas: vendasPreObra,
                }
            }


        }

        await this.setState({
            ...this.state,
            errors: {}
        });

        try {
            this.setLoading(true);
            const response = await axios.post(route('viabilidade.salvar_analise'), data);

            if (response.status === 200 || response.status === 201) {
                this.setState({
                    ...this.state,
                    ...response.data.data,
                    analise_id: response.data.data.analise.id,
                    saved: true
                });

                this.getFluxoTotalData();
                this.getResultsDashboard();

                this.loadVersions();

            }

        } catch (error) {
            if (error.response && error.response.status === 422) {
                let errors = error.response.data.errors;

                this.setState({
                    ...this.state,
                    errors: errors
                })

            }
        } finally {
            this.setLoading(false);
        }


    }
    getAreaPrivativaTotal = (unidades) => {
        return unidades.reduce((current, unidade) => {
            return current + parseFloat(unidade.area_privativa) * parseInt(unidade.quantidade)
        }, 0);
    }

    getTotalUnidades = (unidades) => {
        return unidades.reduce((current, unidade) => current + parseInt(unidade.quantidade), 0);
    }

    getCustoTotal = () => {
        return this.state.custo_unitario_basico * this.state.area_equivalente_total;
    }

    getTotalVendas = () => {
        let total = 0;
        total += this.state.vendas_pre_obra.reduce((current, venda) => current + parseInt(venda?.numero_vendas), 0);
        total += this.state.vendas_durante_obra.reduce((current, venda) => current + parseInt(venda?.numero_vendas), 0);
        total += this.state.vendas_pos_obra.reduce((current, venda) => current + parseInt(venda?.numero_vendas), 0);

        return total;
    }

    openModalVersions = async (show = true) => {
        await this.setState({
            ...this.state,
            showModalVersions: !!show
        });
    }

    closeModalVersions = async () => {
        await this.setState({
            ...this.state,
            showModalVersions: false
        });
    }

    loadVersions = async () => {
        try {
            let id = this.state.analise.parent_id;

            if (!id) {
                id = this.state.analise.id;
            }

            let response = await axios.get(route('viabilidade.analise.versions', id));

            await this.setState({
                ...this.state,
                analise: {
                    ...this.state.analise,
                    versions: response.data
                }
            });

        } catch (e) {
            console.log(e);

        }
    }

    downloadPDF = async () => {
        try {
            await this.setState({
                ...this.state,
                downloading: true
            });

            const response = await axios.get(route('viabilidade.analise.export', this.state.analise.uuid), {
                responseType: 'blob'
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', response.headers['content-disposition'].split('filename=')[1]);
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);


        } catch (e) {
            console.log(e);
        } finally {
            await this.setState({
                ...this.state,
                downloading: false
            });
        }
    }

    render() {

        let custoTotal = this.state.custo_total;
        let vgv = this.calculateVgv();
        let totalPorcentagemPosObra = parseFloat(this.state.pos_obra_porcentagem_entrada) + parseFloat(this.state.pos_obra_porcentagem_parcelas) + parseFloat(this.state.pos_obra_porcentagem_contrato);
        let totalPorcentagemPreObra = parseFloat(this.state.pre_obra_porcentagem_entrada) + parseFloat(this.state.pre_obra_porcentagem_parcelas) + parseFloat(this.state.pre_obra_porcentagem_contrato);
        let totalVendas = this.getTotalVendas();

        let dataLancamento = new Date(this.state.analise.data_lancamento);


        return (<div id="form-viabilidade">
            <div className="card no-border-radius">
                <div className="card-body">
                    {this.state.loading && <Loader/>}

                    <SweetAlert2 {...this.state.swal}
                                 onConfirm={async () => {

                                     this.setState({
                                         swal: {
                                             ...this.state.swal,
                                             show: false
                                         }
                                     })
                                     await this.removerUnidade(this.state.selectedUnidade.uuid);
                                 }}

                                 didClose={() => {
                                     this.setState({
                                         swal: {
                                             ...this.state.swal,
                                             show: false
                                         }
                                     })
                                 }}

                    />


                    <Modal show={this.state.show_modal_preencher_vendas} onHide={() => {
                        this.setState({
                            show_modal_preencher_vendas: false
                        })
                    }}
                           aria-labelledby="example-custom-modal-styling-title">
                        <Modal.Header closeButton>
                            <h5>Preencher Vendas</h5>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="container-fluid">
                                <div className="row">

                                    <div className="col-12">
                                        {this.state.erro_calculo &&
                                            <p className="alert alert-danger alert-dismissible fade show">
                                                {this.state.erro_calculo}
                                            </p>}
                                    </div>

                                    <div className="col-12">
                                        <label htmlFor="porcentagem_vendas_pre_obra">Porcentagem de vendas Pré
                                            Obra</label>
                                        <input type="number" className="form-control"
                                               value={this.state.porcentagem_vendas_pre_obra}
                                               onChange={this.onValueChange}
                                               name="porcentagem_vendas_pre_obra"/>
                                    </div>

                                    <div className="col-12">
                                        <label htmlFor="porcentagem_vendas_durante_obra">Porcentagem de vendas
                                            durante Obra</label>
                                        <input type="number" className="form-control"
                                               value={this.state.porcentagem_vendas_durante_obra}
                                               onChange={this.onValueChange}
                                               name="porcentagem_vendas_durante_obra"/>
                                    </div>

                                    <div className="col-12">
                                        <label htmlFor="porcentagem_vendas_pos_obra">Porcentagem de vendas
                                            Pós-Obra</label>
                                        <input type="number" className="form-control"
                                               value={this.state.porcentagem_vendas_pos_obra}
                                               onChange={this.onValueChange}
                                               name="porcentagem_vendas_pos_obra"/>
                                    </div>

                                    <div className="col-12">
                                        <label htmlFor="porcentagem_vendas_pos_obra">&nbsp;</label>
                                        <button className="btn btn-success btn-block" onClick={this.calcularVendas}>
                                            Calcular Vendas
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </Modal.Body>
                    </Modal>

                    {this.state.errors && Object.keys(this.state.errors).length > 0 && (
                        <div style={{marginBottom: "10px"}}>
                            <ul>
                                {Object.keys(this.state.errors).map((key, index) => {
                                    return (
                                        <li key={index} className="list-group-item">
                                            {this.state.errors[key].map((error, index) => {
                                                return (
                                                    <div key={index} className="alert alert-danger alert-dismissable">
                                                        {error}
                                                    </div>
                                                )
                                            })}
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    )}

                    {this.state.analise?.versions?.length > 0 && <>
                        <div className="pull-right">
                            <span className="badge badge-info">Versão {this.state.analise.version}</span> &nbsp;
                            <button className="button" onClick={this.openModalVersions}>
                                <i className="fa fa-list-ol"></i> Versões &nbsp;
                                <span className="badge badge-info">
                                    {this.state.analise.versions.length}
                                </span>
                            </button>

                            <ModalVersions
                                versions={this.state.analise.versions}
                                show={this.state.showModalVersions}
                                handleClose={this.closeModalVersions}
                                showing_version={this.state.analise.uuid}
                                loadData={this.loadData}
                            />

                        </div>
                    </>}

                    <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                        <Tab eventKey="home" title="Dados de Entrada">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                                    <h5 style={styles.titleBox}>Dados e premissas do Empreendimento a ser
                                        análisado</h5>

                                    <div className="card-body">
                                        <div className="form-group">
                                            <table className="table table-bordered table-sm table-striped">
                                                <thead>
                                                <tr style={styles.titleRow}>
                                                    <th style={styles.titleRow}>Tipo de Unidade</th>
                                                    <th style={styles.titleRow}>Área Privativa</th>
                                                    <th style={styles.titleRow}>Preço Metro</th>
                                                    <th style={styles.titleRow}>Unidades</th>
                                                    <th style={styles.titleRow}>Ações</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.unidades.map((unidade, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{unidade.nome}</td>
                                                            <td>{this.formatNumber(unidade.area_privativa)} m2</td>
                                                            <td>R$ {this.formatNumber(unidade.preco_metro)}</td>
                                                            <td>{unidade.quantidade}</td>
                                                            <td>
                                                                <div className="d-flex justify-content-between">
                                                                    <button className="btn btn-danger btn-sm"
                                                                            onClick={(e) => {
                                                                                e.preventDefault();

                                                                                this.setState({
                                                                                    swal: {
                                                                                        ...this.state.swal,
                                                                                        show: true,
                                                                                    },
                                                                                    selectedUnidade: unidade
                                                                                });

                                                                            }}
                                                                    >
                                                                        <i className="fa fa-trash"></i>
                                                                    </button>

                                                                    <button className="btn btn-success btn-sm"
                                                                            onClick={(e) => {
                                                                                this.setState({
                                                                                    ...this.state,
                                                                                    selectedUnidade: unidade,
                                                                                    editingUnidade: true
                                                                                });

                                                                            }}
                                                                    >
                                                                        <i className="fa fa-edit"></i>
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                                </tbody>
                                            </table>

                                            <FormAdicionarUnidade
                                                onAddUnit={this.adicionarUnidade}
                                                selectedUnidade={this.state.selectedUnidade}
                                                editingUnidade={this.state.editingUnidade}
                                            />


                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="name">Área total privativa</label>
                                            <input type="text"
                                                   value={this.formatNumber(this.state.area_privativa_total)}
                                                   onChange={this.onValueChange}
                                                   className="form-control readonly"
                                                   id="area_total_privativa"
                                                   name="area_total_privativa"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="name">Áreas Comuns (%)</label>
                                            <div className="d-flex justify-content-between">
                                                <div style={{width: '70%'}}>
                                                    <div className="input-group mb-3">
                                                        <InputNumber
                                                            value={this.state.porcentagem_areas_comuns}
                                                            inputClassName="w-100"
                                                            onValueChange={this.onChangePorcentagemAreasComuns}
                                                            name="porcentagem_areas_comuns"
                                                            id="porcentagem_areas_comuns"
                                                            locale="pt-BR" minFractionDigits={2}/>

                                                        <div className="input-group-append">
                                                    <span className="input-group-text"
                                                          id="basic-addon2">
                                                        <span style={{color: '#999999', fontSize: '14px'}}>
                                                            &nbsp; % da área total &nbsp;
                                                        </span>
                                                    </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{width: '30%', marginLeft: '5px'}}>
                                                    <input type="text" readOnly={true} className="readonly form-control"
                                                           value={this.formatNumber(this.state.areas_comuns)}
                                                           id="name" name="areas_comuns"/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="name">Área equivalente total</label>
                                            <input type="text" readOnly={true} className="readonly form-control"
                                                   id="area_equivalente_total"
                                                   value={this.formatNumber(this.state.area_equivalente_total)}
                                                   name="area_equivalente_total"/>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="name">Área privativa média da unidade</label>
                                            <input type="text"
                                                   readOnly={true}
                                                   value={this.formatNumber(this.state.area_privativa_media)}
                                                   className="readonly form-control" id="area_privativa_media"
                                                   name="area_privativa_media"/>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="name">Custo unitário básico</label>
                                            <IntlCurrencyInput
                                                currency="BRL"
                                                config={currencyConfig}
                                                value={this.state.custo_unitario_basico}
                                                onChange={async (event, value, maskedValue) => {

                                                    await this.setState({
                                                        ...this.state,
                                                        custo_unitario_basico: value,
                                                        custo_total: this.getCustoTotal()
                                                    });

                                                }}
                                                style={{padding: '3px'}}
                                                className="form-control"
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="name">Custo Total</label>

                                            <input type="text" className="readonly form-control" id="custo_total"
                                                   readOnly={true}
                                                   value={this.formatNumber(custoTotal)}
                                                   name="custo_total"/>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="data_lancamento">Data de Lançamento</label><br/>
                                            <Calendar value={dataLancamento}
                                                      locale={ptBr} dateFormat="dd/mm/yy"
                                                      onChange={(e) => {
                                                          this.setState({
                                                              ...this.state,
                                                              analise: {
                                                                  ...this.state.analise,
                                                                  data_lancamento: e.value
                                                              }
                                                          })
                                                      }}
                                                      readOnly={true}/>

                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="name">Tempo de Obra (em meses)</label>
                                            <input type="number" className="form-control" id="periodo_obras"
                                                   onChange={this.onValueChange}
                                                   value={this.state.periodo_obras}
                                                   name="periodo_obras"/>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="name">Mês de Ínicio de Obras</label>
                                            <input type="number" className="form-control" id="mes_inicio_de_obras"
                                                   onChange={this.onValueChange}
                                                   value={this.state.mes_inicio_obra}
                                                   name="mes_inicio_obra"/>
                                            <small>Utilize um número Por exemplo: 12</small>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="name">Preço Médio R$</label>

                                            <input type="text" readOnly={true} className="readonly form-control"
                                                   id="area_equivalente_total"
                                                   value={this.formatNumber(this.state.valor_medio_vendas)}
                                                   name="valor_medio_vendas"/>

                                        </div>

                                        <label htmlFor="name">Valor venal total - VGV</label>
                                        <IntlCurrencyInput
                                            currency="BRL"
                                            config={currencyConfig}
                                            value={vgv}
                                            style={{padding: '3px'}}
                                            className="readonly form-control"

                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-5 col-lg-5">
                                    <h5 style={styles.titleBox}>Dados relativo ao negócio Imobiliário</h5>

                                    <div className="card-body">
                                        <div className="row form-group">
                                            <label htmlFor="name">Comissão R$ sobre o terreno</label>
                                            <IntlCurrencyInput
                                                name="valor_comissao_sobre_terreno"
                                                currency="BRL"
                                                onChange={(event, value, maskedValue) => {
                                                    this.setState({
                                                        ...this.state,
                                                        valor_comissao_sobre_terreno: value
                                                    })
                                                }}
                                                config={currencyConfig}
                                                value={this.state.valor_comissao_sobre_terreno}
                                                style={{padding: '3px'}}
                                                className="form-control"

                                            />
                                        </div>

                                        <div className="row form-group">
                                            <label htmlFor="name">Custo de Manutenção futura ( % sobre o VGV) </label>

                                            <InputNumber
                                                value={this.state.porcentagem_custo_manutencao_futura}
                                                onValueChange={this.onValueChange}
                                                inputClassName="form-control"
                                                name="porcentagem_custo_manutencao_futura"
                                                id="porcentagem_custo_manutencao_futura"
                                                locale="pt-BR"
                                                minFractionDigits={2}
                                            />
                                        </div>

                                        <div className="row form-group">
                                            <label htmlFor="name">Despesas de Administração (% sobre o VGV)</label>
                                            <InputNumber
                                                value={this.state.porcentagem_administracao_escritorio}
                                                onValueChange={this.onValueChange}
                                                inputClassName="form-control"
                                                name="porcentagem_administracao_escritorio"
                                                id="porcentagem_administracao_escritorio"
                                                locale="pt-BR"
                                                minFractionDigits={2}
                                            />
                                        </div>

                                        <div className="row form-group">
                                            <label htmlFor="name">Taxa de Juros Efetivo do Financiamento (%)
                                                Anual </label>
                                            <InputNumber
                                                value={this.state.porcentagem_taxa_juros_financiamento}
                                                onValueChange={this.onValueChange}
                                                inputClassName="form-control"
                                                name="porcentagem_taxa_juros_financiamento"
                                                id="porcentagem_taxa_juros_financiamento"
                                                locale="pt-BR"
                                                minFractionDigits={2}
                                            />

                                        </div>

                                        <div className="row form-group">
                                            <label htmlFor="name">Comissão Sobre vendas (%)</label>
                                            <InputNumber
                                                value={this.state.porcentagem_comissao_sobre_vendas}
                                                onValueChange={this.onValueChange}
                                                inputClassName="form-control"
                                                name="porcentagem_comissao_sobre_vendas"
                                                id="porcentagem_comissao_sobre_vendas"
                                                locale="pt-BR"
                                                minFractionDigits={2}
                                            />
                                        </div>

                                        <div className="row form-group">
                                            <label htmlFor="imposto_sobre_faturamento">Impostos Sobre Faturamento
                                                (%)</label>
                                            <InputNumber
                                                value={this.state.porcentagem_impostos_sobre_faturamento}
                                                onValueChange={this.onValueChange}
                                                inputClassName="form-control"
                                                name="porcentagem_impostos_sobre_faturamento"
                                                id="porcentagem_impostos_sobre_faturamento"
                                                locale="pt-BR"
                                                minFractionDigits={2}
                                            />

                                        </div>

                                        <div className="row form-group">
                                            <label htmlFor="imposto_sobre_faturamento">
                                                Percentual de Financiamento (% sobre o custo direto)
                                            </label>
                                            <InputNumber
                                                value={this.state.porcentagem_financiamento}
                                                onValueChange={this.onValueChange}
                                                inputClassName="form-control"
                                                name="porcentagem_financiamento"
                                                id="porcentagem_financiamento"
                                                locale="pt-BR"
                                                minFractionDigits={2}
                                            />
                                        </div>

                                        <hr/>
                                        <div className="row form-group">
                                            <label htmlFor="name">Custo Mensal de Publicidade Antes do Lançamento(%
                                                sobre o VGV)</label>
                                            <InputNumber
                                                value={this.state.porcentagem_custo_publicidade_pre_lancamento}
                                                onValueChange={this.onValueChange}
                                                inputClassName="form-control"
                                                name="porcentagem_custo_publicidade_pre_lancamento"
                                                id="porcentagem_custo_publicidade_pre_lancamento"
                                                locale="pt-BR"
                                                minFractionDigits={2}
                                            />

                                        </div>
                                        <div className="row form-group">
                                            <label htmlFor="name">Custo Mensal de Publicidade no Lançamento(% sobre o
                                                VGV)</label>
                                            <InputNumber
                                                value={this.state.porcentagem_custo_publicidade_lancamento}
                                                onValueChange={this.onValueChange}
                                                inputClassName="form-control"
                                                name="porcentagem_custo_publicidade_lancamento"
                                                id="porcentagem_custo_publicidade_lancamento"
                                                locale="pt-BR"
                                                minFractionDigits={2}
                                            />
                                        </div>

                                        <div className="row form-group">
                                            <label htmlFor="name">Custo Mensal de Publicidade Pós Lançamento(% sobre o
                                                VGV)</label>
                                            <InputNumber
                                                value={this.state.porcentagem_custo_publicidade_pos_lancamento}
                                                onValueChange={this.onValueChange}
                                                inputClassName="form-control"
                                                name="porcentagem_custo_publicidade_pos_lancamento"
                                                id="porcentagem_custo_publicidade_pos_lancamento"
                                                locale="pt-BR"
                                                minFractionDigits={2}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="profile" title="Tabela de Vendas">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                                    <button className="btn btn-sm btn-default" onClick={() => {
                                        this.setState({
                                            show_modal_preencher_vendas: true
                                        });
                                    }}>
                                        Preencher Automaticamente
                                    </button>
                                </div>
                            </div>
                            <div className="row">

                                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                    <h5 style={styles.titleBox}>Fluxo de Pagamento Até a Entrega</h5>
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label htmlFor="name">Número de Parcelas</label>
                                            <input type="text" className="form-control"
                                                   onChange={this.onValueChange}
                                                   value={this.state.pre_obra_numero_parcelas}
                                                   id="pre_obra_numero_parcelas"
                                                   name="pre_obra_numero_parcelas"/>
                                        </div>

                                        <div className="form-group">
                                            <label>Sinal + Contrato (%)</label>
                                            <input type="text" className="form-control"
                                                   onChange={this.onValueChange}
                                                   value={this.state.pre_obra_porcentagem_contrato}
                                                   id="pre_obra_porcentagem_contrato"
                                                   name="pre_obra_porcentagem_contrato"/>
                                        </div>

                                        <div className="form-group">
                                            <label>Pagamentos durante a obra (%)</label>
                                            <input type="text" className="form-control"
                                                   onChange={this.onValueChange}
                                                   value={this.state.pre_obra_porcentagem_parcelas}
                                                   id="pre_obra_porcentagem_parcelas"
                                                   name="pre_obra_porcentagem_parcelas"/>
                                        </div>

                                        <div className="form-group">
                                            <label>Chaves (%)</label>
                                            <input type="text" className="form-control"
                                                   onChange={this.onValueChange}
                                                   value={this.state.pre_obra_porcentagem_entrada}
                                                   id="pre_obra_porcentagem_entrada"
                                                   name="pre_obra_porcentagem_entrada"/>
                                        </div>

                                        <div className="form-group">
                                            <label>Total</label>
                                            <input type="text" className="readonly form-control"
                                                   readOnly={true}
                                                   value={this.formatNumber(totalPorcentagemPreObra)}
                                                   id="total" name="total"/>
                                        </div>

                                        <hr/>
                                        <div className="d-flex justify-content-around">
                                            <div className="align-self-start" style={{width: '50%'}}>
                                                <label htmlFor="">Total de Unidades</label>
                                            </div>
                                            <div className="align-self-end" style={{width: '50%'}}>
                                                    <span className="text-right float-right">
                                                        <label htmlFor="">
                                                            {this.state.total_unidades}
                                                        </label>
                                                    </span>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-around">
                                            <div className="align-self-start" style={{width: '50%'}}>
                                                <label htmlFor="">Unidades á venda</label>
                                            </div>
                                            <div className="align-self-end" style={{width: '50%'}}>
                                                    <span className="text-right float-right">
                                                        <label htmlFor="">
                                                            {this.state.total_unidades - this.state.permutas}
                                                        </label>
                                                    </span>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-around">
                                            <div className="align-self-start" style={{width: '50%'}}>
                                                <label htmlFor="">Total de Vendas</label>
                                            </div>
                                            <div className="align-self-end" style={{width: '50%'}}>
                                                    <span className="text-right float-right">
                                                        <label htmlFor="">
                                                            {totalVendas}
                                                        </label>
                                                    </span>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                    <h5 style={styles.titleBox}>Fluxo de pagamento pós-obra</h5>
                                    <div className="form-group">
                                        <label>Número de Parcelas</label>
                                        <input type="text" className="form-control"
                                               onChange={this.onValueChange}
                                               value={this.state.pos_obra_numero_parcelas}
                                               id="pos_obra_numero_parcelas" name="pos_obra_numero_parcelas"
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label>Contrato (%)</label>
                                        <input type="text" className="form-control"
                                               onChange={this.onValueChange}
                                               value={this.state.pos_obra_porcentagem_contrato}
                                               id="pos_obra_porcentagem_contrato"
                                               name="pos_obra_porcentagem_contrato"
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label>Entrada (%)</label>
                                        <input type="text" className="form-control"
                                               onChange={this.onValueChange}
                                               value={this.state.pos_obra_porcentagem_entrada}
                                               id="pos_obra_porcentagem_entrada"
                                               name="pos_obra_porcentagem_entrada"/>
                                    </div>

                                    <div className="form-group">
                                        <label>Mensais (%)</label>
                                        <input type="text" className="form-control"
                                               onChange={this.onValueChange}
                                               value={this.state.pos_obra_porcentagem_parcelas}
                                               id="pos_obra_porcentagem_parcelas"
                                               name="pos_obra_porcentagem_parcelas"/>
                                    </div>

                                    <div className="form-group">
                                        <label>Total</label>
                                        <input type="text" className="readonly form-control"
                                               readOnly
                                               value={this.formatNumber(totalPorcentagemPosObra)}
                                               id="total_pos_obra" name="total_pos_obra"/>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-4 col-lg-4">

                                    {this.state.unidades.length && <>
                                        <h4>Vendas Pré Obra</h4>
                                        <TableFormVendas
                                            vendas={this.state.vendas_pre_obra}
                                            unidades={this.state.unidades}
                                            periodo_vendas="vendas_pre_obra"
                                            updateVendaQuantidadeVenda={this.updateVendaQuantidadeVenda}
                                        />
                                    </>}

                                </div>
                                <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                                    {this.state.unidades.length && <>
                                        <h4>Vendas Durante Obra</h4>
                                        <TableFormVendas
                                            vendas={this.state.vendas_durante_obra}
                                            unidades={this.state.unidades}
                                            periodo_vendas="vendas_durante_obra"
                                            updateVendaQuantidadeVenda={this.updateVendaQuantidadeVenda}
                                        />
                                    </>}
                                </div>
                                <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                                    {this.state.unidades.length && <>

                                        <h4>Vendas Pós Obra</h4>

                                        <TableFormVendas
                                            vendas={this.state.vendas_pos_obra}
                                            unidades={this.state.unidades}
                                            periodo_vendas="vendas_pos_obra"
                                            updateVendaQuantidadeVenda={this.updateVendaQuantidadeVenda}
                                        />

                                        <hr/>
                                        <FormAdicionarVenda
                                            unidades={this.state.unidades}
                                            addVenda={this.addVenda}/>

                                    </>}
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="contact" title="Custos Anteriores">

                            <div className="row">
                                <div className="col-8">
                                    <div className="form-group">
                                        <label htmlFor="">Empreendimento</label>
                                        <input type="text"
                                               onChange={this.onValueChange}
                                               value={this.state.analise.nome}
                                               className="form-control" id="analise_nome" name="analise_nome"/>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className="form-group">
                                        <label htmlFor="">Cidade</label>
                                        <input type="text"
                                               onChange={this.onValueChange}
                                               value={this.state.analise.local}
                                               className="form-control" id="analise_local" name="analise_local"/>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <h5 style={styles.titleBox}>Linha do Tempo</h5>
                                    <div className="form-group">
                                        <label htmlFor="">Custo de oportunidade adotado (ao mês %)</label>
                                        <input type="text"
                                               onChange={this.onValueChange}
                                               value={this.state.custo_oportunidade}
                                               className="form-control" id="custo_oportunidade"
                                               name="custo_oportunidade"/>
                                    </div>

                                </div>
                            </div>

                            <FormDepesas
                                currencyConfig={currencyConfig}
                                styles={styles}
                                addDespesa={this.addDespesa}
                                editingDespesa={this.state.editingDespesa}
                                selectedDespesa={this.state.selectedDespesa}
                                setSelectDespesa={this.setSelectDespesa}
                            />
                            <TabelaDespesas
                                formatNumber={this.formatNumber}
                                despesas={this.state.despesas}
                                removeDespesa={this.removeDespesa}
                                setSelectDespesa={this.setSelectDespesa}
                            />

                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">

                                    <h5 style={styles.titleBox}>Permuta</h5>

                                    <div className="form-check">
                                        <label>Permuta Financeira</label>
                                        <label className="form-check-label">
                                            <input className="form-check-input" type="radio"
                                                   checked={this.state.possui_permuta_financeira === true}
                                                   onChange={this.onValueChangeCheckBox}
                                                   name="possui_permuta_financeira"
                                                   value="Sim"/>
                                            &nbsp;

                                            Sim
                                        </label>
                                        <label className="form-check-label">
                                            <input className="form-check-input" type="radio"
                                                   onChange={this.onValueChangeCheckBox}
                                                   checked={this.state.possui_permuta_financeira === false}
                                                   name="possui_permuta_financeira"
                                                   value="Não"/>
                                            &nbsp;
                                            Não
                                        </label>
                                    </div>
                                </div>

                                {this.state.possui_permuta_financeira && <>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="form-group">
                                            <label htmlFor="">Porcentagem Permuta (%)</label>
                                            <input type="text"
                                                   onChange={this.onValueChange}
                                                   value={this.state.porcentagem_permuta_financeira}
                                                   className="form-control" id="porcentagem_permuta_financeira"
                                                   name="porcentagem_permuta_financeira"/>
                                        </div>
                                    </div>
                                </>}

                            </div>
                        </Tab>

                        <Tab eventKey="fluxo" title="Fluxo Total">

                            {this.state.analise_id > 0 && <>
                                <FluxoTotalTabela
                                    imovel_uuid={this.state.imovel_uuid}
                                    analise_uuid={this.state.analise.uuid}
                                    fluxo_total={this.state.fluxo_total}
                                    loading_fluxo_total={this.state.loading_fluxo_total}
                                    evolucao_obra={this.state.evolucao_obra}
                                    loadData={this.getFluxoTotalData}
                                    analise={this.state.analise}
                                />

                                <hr/>
                                <FluxoTotalChart
                                    imovel_uuid={this.state.imovel_uuid}
                                    analise_uuid={this.state.analise.uuid}
                                    fluxo_total={this.state.fluxo_total}
                                    loading_fluxo_total={this.state.loading_fluxo_total}
                                    evolucao_obra={this.state.evolucao_obra}
                                    loadData={this.getFluxoTotalData}

                                />
                            </>
                            }

                        </Tab>
                        <Tab eventKey="resultados" title="Resultados">

                            {this.state.analise_id > 0 && <DemonstrativoResultados
                                formatNumber={this.formatNumber}
                                resultado={this.state.resultados}
                                loading_resultados_dasboard={this.state.loading_resultados_dasboard}
                            />}

                        </Tab>
                    </Tabs>
                </div>
                <div className="card-footer">
                    <div className="row">
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                            <button className="btn btn-success btn-block" onClick={this.submit}
                                    style={{height: "60px"}}>
                                Salvar
                            </button>
                        </div>

                        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                            {this.state.analise?.id > 0 && <>
                                <button className="btn btn-primary btn-block" onClick={this.submitNewVersion}
                                        style={{height: "60px"}}>
                                    Salvar Como Nova Versão
                                </button>
                            </>}
                        </div>

                        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                            {this.state.analise?.id > 0 && <>
                                <button className="btn btn-default btn-block" onClick={this.downloadPDF}
                                        style={{height: "60px"}}>
                                    <i className="fa fa-file-pdf"></i> &nbsp;
                                    Baixar PDF &nbsp;
                                    {this.state.downloading && <i className="fas fa-spinner fa-spin"></i>}
                                    {!this.state.downloading && <>&nbsp;</>}
                                </button>
                            </>}
                        </div>

                    </div>
                </div>
            </div>
        </div>)

    }
}

const styles = {
    titleBox: {
        backgroundColor: '#5B7537', color: 'white', padding: '10px',
    },

    titleRow: {
        backgroundColor: '#5B7537',
        color: 'white',
        padding: '20px',
        textAlign: 'center',
        fontWeight: 'bold',
        height: '30px'
    }
}

if (document.getElementById('js-viabilidade-analise-form')) {
    ReactDOM.render(<AnaliseForm/>, document.getElementById('js-viabilidade-analise-form'));
}
